import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import LoginTemplate from '@/templates/Login';
import { loginLead } from '@/services/auth.service';
import PropTypes from 'prop-types';
import { redirectTo } from 'utils/router';
import { setAuth } from '@/services/identity.service';
import { getCookieAuth } from 'utils/common';

const Login = ({ initialMobileNumber }) => {
    const [otp, setOtp] = useState('');
    const [isCheckedRememberMe, setIsCheckedRememberMe] = useState(true);

    useEffect(() => {
        setAuth({ isRemember: isCheckedRememberMe });
    }, [isCheckedRememberMe]);

    const handleRememberMe = () => {
        setIsCheckedRememberMe((prev) => !prev);
    };

    const handleResend = () => {};

    const validationSchema = yup.object().shape({
        mobileNumber: yup
            .string()
            .required('Mobile number is required')
            .matches(/^\d{10}$/, 'Mobile number must be 10 digits'),
    });

    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        setError,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            mobileNumber: initialMobileNumber || '',
        },
    });

    const onSubmit = async (data) => {
        const response = await loginLead({
            mobileNumber: '+91' + data.mobileNumber,
        });

        if (response.status) {
            const encodedSession = encodeURIComponent(
                response?.entity?.session
            );
            redirectTo(
                `/verify-otp?mobileNumber=${data.mobileNumber}&session=${encodedSession}&from=login`
            );
        } else {
            setError('mobileNumber', {
                type: 'manual',
                message:
                    response?.errorMessage === 'User Not Found' &&
                    `Mobile Number doesn't exist!`,
            });
        }
    };

    const handleMobileChange = (event) => {
        let inputValue = event.target.value;
        const maxLength = 10;
        if (inputValue.length > maxLength) {
            inputValue = inputValue.slice(0, maxLength);
            event.target.value = inputValue;
        }
    };

    return (
        <LoginTemplate
            register={register}
            setValue={setValue}
            getValues={getValues}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            errors={errors}
            handleRememberMe={handleRememberMe}
            isCheckedRememberMe={isCheckedRememberMe}
            handleMobileChange={handleMobileChange}
            handleResend={handleResend}
            otpSent={false}
            otp={otp}
            setOtp={setOtp}
        />
    );
};

Login.propTypes = {
    initialMobileNumber: PropTypes.string,
};
export default Login;

export async function getServerSideProps(ctx) {
    const mobileNumber = ctx.query.mobileNumber || '';

    if (getCookieAuth(ctx) && getCookieAuth(ctx).id_token) {
        return {
            redirect: {
                destination: '/delivery-details',
                permanent: false,
            },
        };
    }

    return {
        props: { initialMobileNumber: mobileNumber },
    };
}
