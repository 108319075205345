import * as HttpService from './http.service';
import { LOGIN_LEAD_URL, SIGNUP_LEAD_URL, VERIFY_OTP_URL } from './url.service';

export const signupLead = (data) => {
    return HttpService.postWithOutAuth(SIGNUP_LEAD_URL, data);
};

export const loginLead = (data) => {
    return HttpService.postWithOutAuth(LOGIN_LEAD_URL, data);
};

export const verifyOtp = (data) => {
    return HttpService.postWithOutAuth(VERIFY_OTP_URL, data);
};
