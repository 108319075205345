import PropTypes from 'prop-types';
import Button from '@/atoms/Button';
import Checkbox from '@/atoms/Checkbox';
import Heading from '@/atoms/Heading';
import InputBox from '@/atoms/InputBox';
import Text from '@/atoms/Text';
import Image from 'next/image';
import Link from 'next/link';

const Login = ({
    register,
    setValue,
    getValues,
    handleSubmit,
    onSubmit,
    errors,
    handleRememberMe,
    isCheckedRememberMe,
    handleMobileChange,
}) => {
    return (
        <>
            <div className="bg-grey-shade6 min-h-screen py-5 md:py-14 ">
                <div className="container">
                    <div className="flex flex-wrap bg-white rounded-lg py-6 md:py-10 lg:py-[60px] px-4 md:px-10 lg:px-[100px] border border-grey-shade5">
                        <div className="w-full lg:w-5/12 lg:border-r border-grey-shade5 lg:pr-10">
                            <Heading
                                type="h2"
                                fontFamily="font-sectionHeading"
                                fontColor="text-grey-shade1"
                                className=" hidden md:block"
                            >
                                Please enter your details
                            </Heading>
                            <Heading
                                type="h2"
                                fontFamily="font-sectionHeading"
                                className="text-grey-shade1 block md:hidden"
                            >
                                Login with your mobile number
                            </Heading>

                            <Text
                                variant="body2"
                                className="mb-10 xl:mb-20 hidden md:block"
                            >
                                Share a few details about yourself to get
                                started
                            </Text>
                            <Image
                                src="/images/login_img.svg"
                                width={380}
                                height={376}
                                alt=""
                                className="mx-auto lg:mx-0 max-w-[160px] md:max-w-[240px] xl:max-w-max my-6 lg:my-10"
                            />
                        </div>
                        <div className="w-full md:max-w-[400px] lg:max-w-max lg:w-7/12 pl-0 lg:pl-20">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Heading
                                    type="h4"
                                    fontFamily="font-body"
                                    className="mb-5 hidden md:block"
                                    fontWeight="font-medium"
                                >
                                    Login with your mobile number
                                </Heading>

                                <InputBox
                                    id="mobileNumber"
                                    labelClass="font-medium"
                                    name="mobileNumber"
                                    type="text"
                                    isLabel={true}
                                    labelText="Enter Registered Mobile Number"
                                    placeholder=""
                                    register={register}
                                    dbName={'mobileNumber'}
                                    maxLength={10}
                                    errorMessage={
                                        errors.mobileNumber &&
                                        errors.mobileNumber?.message
                                    }
                                    isRequired={true}
                                    isError={errors.mobileNumber ? true : false}
                                    getValues={getValues}
                                    onChange={(e) => {
                                        const numericValue =
                                            e.target.value.replace(
                                                /[^0-9]/g,
                                                ''
                                            );
                                        return setValue(
                                            'mobileNumber',
                                            numericValue
                                        );
                                    }}
                                    onInput={handleMobileChange}
                                />

                                <Checkbox
                                    containerClass=" w-full mt-4"
                                    checkBoxLabel="Remember Me"
                                    handleChange={handleRememberMe}
                                    isSelected={isCheckedRememberMe}
                                />
                                <div className="flex flex-col md:flex-row flex-wrap gap-5 mt-[114px] md:mt-10 lg:mt-[303px]">
                                    <Button
                                        label="Login"
                                        size="default"
                                        type="submit"
                                    />
                                    <Link href="/signup">
                                        <Button
                                            label="Create an account"
                                            style="ghost"
                                            size="default"
                                        />
                                    </Link>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

Login.defaultProps = {};

Login.propTypes = {
    register: PropTypes.func,
    setValue: PropTypes.func,
    getValues: PropTypes.func,
    handleSubmit: PropTypes.func,
    onSubmit: PropTypes.func,
    errors: PropTypes.object,
    handleRememberMe: PropTypes.func,
    isCheckedRememberMe: PropTypes.bool,
    handleMobileChange: PropTypes.func,
};

export default Login;
